import RectangleButton from '../../components/common/button/rectangle_button.tsx'
import { IoMdAdd } from 'react-icons/io'
import { ReactNode } from 'react'
import InputField from '../../components/common/textfield/input_field.tsx'
import { IoSearch } from 'react-icons/io5'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import Chip from '@/components/common/chip/chip.tsx'
import ChipGroup from '@/components/common/chip/chip_group.tsx'

const Components = () => {
    return (
        <div className={'bg-orange-100 min-h-screen w-full p-6'}>
            <h1 className={'text-4xl font-open-sans font-semibold mt-4 text-center'}>Components</h1>

            <div className={'flex flex-wrap gap-x-10 gap-y-6 mt-20 '}>
                {/*  rectangular button*/}
                <ComponentContainer name={'RectangleButton'}>
                    <RectangleButton text={'create addon'} prefixIcon={<IoMdAdd />} />
                </ComponentContainer>

                {/*  input field*/}
                <ComponentContainer name={'InputField'}>
                    <InputField placeholder={'create addon'} prefixIcon={<IoMdAdd />} suffixIcon={<IoSearch />} />
                </ComponentContainer>

                {/*  drop down select*/}
                <ComponentContainer name={'Select'}>
                    <Select>
                        <SelectTrigger className="w-[400px]">
                            <SelectValue placeholder="Theme" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="light">Light</SelectItem>
                            <SelectItem value="dark">Dark</SelectItem>
                            <SelectItem value="system">System</SelectItem>
                        </SelectContent>
                    </Select>

                    <a target={'_blank'} className={'underline'} href="https://ui.shadcn.com/docs/components/select">
                        Documentation
                    </a>
                </ComponentContainer>

                {/*  chip*/}
                <ComponentContainer name={'Chip'}>
                    <Chip />
                </ComponentContainer>

                <ComponentContainer name={'ChipGroup'}>
                    <ChipGroup values={['Monthly', 'Annually']} defaultValue={'Monthly'} />
                </ComponentContainer>
            </div>
        </div>
    )
}
export default Components

const ComponentContainer: React.FC<{ children: ReactNode; name?: string }> = ({ children, name }) => {
    return (
        <div className={'bg-white inline-flex px-4 py-8 rounded-md gap-4 flex-col justify-center items-center'}>
            {children}
            <p>{name}</p>
        </div>
    )
}
