import { ModelCustomer } from '@/api/models/model_customer'
import { Drawer } from 'antd'
import { useEffect, useState } from 'react'
import { FormBuilder } from '@/@ui-lib/molecules'
import { ProductAPI } from '@/api/req/product_api'
import { PlanAPI } from '@/api/req/plan_api'

interface PlanDrawerProps {
    onSuccess: (value: any) => void
    onError: (value: any) => void
    onClose: () => void
    customer?: ModelCustomer
    drawerVisibility: boolean
}

export default function PlanDrawer({ onSuccess, onError, onClose, customer, drawerVisibility }: PlanDrawerProps) {
    const [formFields, setFormFields] = useState([
        {
            name: 'product',
            label: 'Product',
            type: 'select',
            placeholder: 'Select Product',
            validation: {
                required: 'Product must be selected',
            },
        },
        {
            name: 'name',
            label: 'Name',
            type: 'text',
            placeholder: 'Enter plan name',
            validation: {
                required: 'Plan name required',
            },
        },
        {
            name: 'slug',
            label: 'Slug',
            type: 'text',
            placeholder: 'Enter plan slug',
            validation: {
                required: 'Plan slug required',
            },
        },
        {
            name: 'description',
            label: 'Description',
            type: 'text',
            placeholder: 'Enter plan description',
        },
    ])

    useEffect(() => {
        ProductAPI.getAll(false).then((result) => {
            const data = result.map((item) => ({
                id: item.id,
                value: item.name,
            }))

            setFormFields((prevFields) =>
                prevFields.map((field) => (field.name === 'product' ? { ...field, options: data } : field))
            )
        })
    }, [])

    const submitForm = async (data: Record<string, any>) => {
        const payload = {
            slug: data.slug,
            name: data.name,
            type: 'base',
            product_id: data.product,
            description: data.description,
        }
        await PlanAPI.createPlan(false, payload).then((result) => {
            if (result) {
                onSuccess({})
            } else {
                onError({})
            }
        })
    }
    return (
        <Drawer title={customer ? 'Edit Plan' : 'Add Plan'} onClose={onClose} open={drawerVisibility}>
            <FormBuilder fields={formFields} onSubmit={submitForm} />
        </Drawer>
    )
}
