import React, { useState } from 'react'
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'

interface FlexDropdownProps {
    options: string[]
    placeHolder?: string
    defaultOpen?: boolean
    onSelect: (selectedOption: string) => void
}

const FlexDropdown: React.FC<FlexDropdownProps> = ({ options, onSelect, placeHolder, defaultOpen = false }) => {
    const [value, setValue] = useState(placeHolder)

    const handleChange = (event: SelectChangeEvent) => {
        const selectedValue = event.target.value
        setValue(selectedValue)
        onSelect(selectedValue)
    }

    return (
        <div className={'bg-white inline-flex'}>
            <Select
                IconComponent={KeyboardArrowDown}
                value={value}
                variant="standard"
                defaultOpen={defaultOpen}
                disableUnderline={true}
                defaultValue={options[0]}
                autoWidth={true}
                onChange={handleChange}
                sx={{
                    borderWidth: 0,
                    borderRadius: 2,
                    padding: '0px 10px',
                    border: '1px solid #E0E0E0',
                    '.MuiSelect-icon': {
                        color: '#E85426',
                    },
                    overflow: 'hidden',
                }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 200,
                        },
                    },
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} value={option} sx={{ whiteSpace: 'normal' }}>
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                whiteSpace: 'normal',
                            }}
                        >
                            {option}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}

export default FlexDropdown
