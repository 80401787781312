import DeleteIcon from '@mui/icons-material/Delete'
import { PlanConfig } from '../CustomerSubscription'
import { useState } from 'react'
import { IoMdClose } from 'react-icons/io'

interface PlanConfigTableProps {
    planConfig: PlanConfig[]
    onValueChanged: (key: string, value: string) => void
    onDelete?: (key: string) => void
}

export default function PlanConfigTable({ planConfig, onValueChanged, onDelete }: PlanConfigTableProps) {
    const [showTiers, setShowTiers] = useState<{ [key: string]: boolean }>({})

    const calculateTieredPrice = (config: PlanConfig) => {
        if (!config.tiered_price) return 0
        const quantity = config.quantity

        for (const tier of config.tiered_price) {
            const upTo = tier.up_to === 999999999 ? Infinity : Number(tier.up_to)
            if (quantity <= upTo) {
                return tier.price
            }
        }

        return 0
    }

    return (
        <div className="border rounded-xl pt-2 pb-2 bg-gray-100">
            <table className="min-w-full">
                <thead className="rounded-xl">
                    <tr className="bg-gray-100">
                        <th className="px-4 py-2 text-left text-gray-600">Charge</th>
                        <th className="px-4 py-2 text-left text-gray-600">Quantity</th>
                        <th className="px-4 py-2 text-left text-gray-600">Price</th>
                        {onDelete && <th className="px-4 py-2 text-left text-gray-600"></th>}
                    </tr>
                </thead>
                <tbody>
                    {planConfig.map((config, index) => (
                        <tr key={index} className="border-t bg-white items-center align-middle">
                            <td className="px-4 py-3">{config.name}</td>

                            <td className="px-4 py-3">
                                {config.isEditable ? (
                                    <input
                                        type="number"
                                        min="0"
                                        className="w-full p-2 text-sm border rounded border-gray-300 focus:border-orange-600 focus:outline-none"
                                        value={config.quantity}
                                        onChange={(e) => onValueChanged(config.name, e.target.value)}
                                    />
                                ) : (
                                    <span>{config.quantity}</span>
                                )}
                            </td>

                            {config.type === 'FLAT' ? (
                                <td className="px-4 py-3">
                                    ${config.price.toFixed(2)} {config.unit ? `/ ${config.unit}` : ''} /{' '}
                                    {config.billingPeriod}
                                </td>
                            ) : (
                                <td>
                                    <td className="px-4  py-3">
                                        <div className={'flex gap flex-col items-start justify-center'}>
                                            <span>
                                                {'$ '}
                                                {calculateTieredPrice(config).toFixed(2)}{' '}
                                                {config.unit ? `/ ${config.unit}` : ''} / {config.billingPeriod}
                                            </span>

                                            <button
                                                onClick={() => setShowTiers({ ...showTiers, [config.name]: true })}
                                                className="ml-2 text-sm text-blue-500 underline"
                                            >
                                                View Tiers
                                            </button>
                                        </div>

                                        {showTiers[config.name] && (
                                            <div className="fixed top-0 z-[200] left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                                                <div className="bg-white p-6 rounded-lg shadow-lg w-500">
                                                    <div className={'flex items-start justify-between gap-12'}>
                                                        <h3 className="text-base font-normal mb-4 text-center text-gray-800">
                                                            Price Tiers for {config.name}
                                                        </h3>

                                                        <button
                                                            onClick={() =>
                                                                setShowTiers({ ...showTiers, [config.name]: false })
                                                            }
                                                        >
                                                            <IoMdClose className={'text-xl h-full'} />
                                                        </button>
                                                    </div>

                                                    <div className={'rounded-lg border border-gray-300'}>
                                                        <table className="min-w-full rounded-lg  border-gray-300 border border-gray-300-black overflow-clip divide-y divide-gray-200">
                                                            <thead>
                                                                <tr className="bg-gray-100">
                                                                    <th className="px-4 text-gray-900 font-normal rounded-md border border-gray-300  py-2 text-left text-gray-600">
                                                                        Range
                                                                    </th>
                                                                    <th className="px-4 text-gray-900 font-normal rounded-md border border-gray-300 py-2 text-left text-gray-600">
                                                                        Price
                                                                    </th>
                                                                    <th className="px-4 text-gray-900 font-normal border border-gray-300 rounded-md py-2 text-left text-gray-600">
                                                                        Currency
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {config.tiered_price?.map((tier, tierIndex) => {
                                                                    const prevUpTo =
                                                                        tierIndex === 0
                                                                            ? 0
                                                                            : Number(
                                                                                  config.tiered_price![tierIndex - 1]
                                                                                      .up_to
                                                                              ) + 1
                                                                    const currentUpTo =
                                                                        tierIndex === config.tiered_price!.length - 1 &&
                                                                        tier.up_to === 999999999
                                                                            ? '∞'
                                                                            : tier.up_to

                                                                    return (
                                                                        <tr
                                                                            key={tierIndex}
                                                                            className="border border-gray-300 hover:bg-gray-50"
                                                                        >
                                                                            <td className="border border-gray-300 px-4  py-2">
                                                                                {prevUpTo} - {currentUpTo}
                                                                            </td>
                                                                            <td className="border border-gray-300 px-4  py-2 font-medium">
                                                                                $ {tier.price.toFixed(2)}
                                                                            </td>
                                                                            <td className="border border-gray-300 px-4  py-2">
                                                                                {tier.currency || 'USD'}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {/* <button */}
                                                    {/*     onClick={() => */}
                                                    {/*         setShowTiers({ ...showTiers, [config.name]: false }) */}
                                                    {/*     } */}
                                                    {/*     className="mt-4 w-full px-4 py-2 bg-orange-500 text-white font-semibold rounded hover:bg-orange-600 transition duration-300" */}
                                                    {/* > */}
                                                    {/*     Close */}
                                                    {/* </button> */}
                                                </div>
                                            </div>
                                        )}
                                    </td>
                                </td>
                            )}

                            {/* Delete button */}
                            {onDelete && (
                                <td className="px-4 py-3">
                                    <button onClick={() => onDelete(config.name)} className="text-red-500">
                                        <DeleteIcon />
                                    </button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
