import { ModelUser } from '@/api/models/model_user'
import supabase from '@/utils/supabase'
import { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

interface UserProviderProps {
    children: ReactNode
}
interface UserContextProp {
    user: ModelUser
    setUser: (user: ModelUser) => void
}
const UserContext = createContext<UserContextProp>({} as UserContextProp)

export const UserProvider = ({ children }: UserProviderProps) => {
    const [user, setUser] = useState<ModelUser>({} as ModelUser)

    useEffect(() => {
        try {
            const user = JSON.parse(localStorage.getItem('user')!) as ModelUser
            setUser(user)
        } catch (_) {
            supabase.auth.signOut()
            ;<Navigate to={'/login'} />
        }
    }, [])

    return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>
}

// eslint-disable-next-line react-refresh/only-export-components
export const useUser = () => useContext(UserContext)
