import axios, { AxiosError } from 'axios'
import supabase from '../../utils/supabase'

let client: ReturnType<typeof axios.create> | null = null

export async function initializeClient() {
    // Get the session and token
    const session = await supabase.auth.getSession()
    const token = session.data.session?.access_token

    const baseUrl = import.meta.env.VITE_API_URL

    client = axios.create({
        baseURL: baseUrl,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    const errorHandler = (error: AxiosError) => {
        const statusCode = error.response?.status

        if (statusCode && statusCode === 401) {
            console.log(error)
        }

        return Promise.reject(error)
    }

    client.interceptors.response.use(undefined, (error: AxiosError) => {
        return errorHandler(error)
    })
}

// Immediately invoke the function to initialize the client
initializeClient().catch(console.error)

// Function to get the initialized client
async function getClient() {
    if (!client) {
        await initializeClient()
    }
    return client
}

export { getClient }
