import { forwardRef, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Button, Input, Select } from '@/@ui-lib/atoms'

interface FieldConfig {
    name: string
    label: string
    type: string
    placeholder: string
    multi_select?: boolean
    options?: { id: string; value: string }[]
    validation?: Record<string, any>
}

interface FormBuilderProps {
    fields: FieldConfig[]
    onSubmit: (data: Record<string, any>) => void
}

const FormBuilder = forwardRef<HTMLFormElement, FormBuilderProps>(({ fields, onSubmit }, ref) => {
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm()

    const nameValue = watch('name')
    useEffect(() => {
        if (nameValue) {
            const slug = nameValue
                .toLowerCase()
                .replace(/[^a-z0-9]+/g, '-')
                .replace(/^-+|-+$/g, '')

            setValue('slug', slug)
        }
    }, [nameValue, setValue])
    return (
        <form ref={ref} onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            {fields.map((field) => (
                <div key={field.name} className="mb-4">
                    <Controller
                        name={field.name}
                        control={control}
                        rules={field.validation}
                        render={({ field: controllerField }) => {
                            switch (field.type) {
                                case 'text':
                                case 'number':
                                case 'email':
                                    return (
                                        <Input
                                            name={field.name}
                                            label={field.label}
                                            placeholder={field.placeholder}
                                            field={controllerField}
                                            errors={errors}
                                            required={field.validation?.required}
                                        />
                                    )
                                case 'select':
                                    return (
                                        <Select
                                            label={field.label}
                                            name={field.name}
                                            options={field.options || []}
                                            field={controllerField}
                                            placeholder={field.placeholder || 'Select an option'}
                                            required={field.validation?.required}
                                            errors={errors}
                                        />
                                    )
                                case 'textarea':
                                    return (
                                        <textarea
                                            {...controllerField}
                                            id={field.name}
                                            placeholder={field.placeholder}
                                            className={`block w-full px-3 py-2 border ${
                                                errors[field.name] ? 'border-red-500' : 'border-gray-300'
                                            } rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm`}
                                        />
                                    )
                                default:
                                    return <></>
                            }
                        }}
                    />
                </div>
            ))}
            <Button
                key={isSubmitting ? 'loading' : 'not-loading'}
                label="Submit"
                type="submit"
                fullWidth
                isLoading={isSubmitting}
                disabled={isSubmitting}
            />
        </form>
    )
})

FormBuilder.displayName = 'FormBuilder'

export default FormBuilder
