import React, { ReactNode } from 'react'

interface Props {
    suffixIcon?: ReactNode
    prefixIcon?: ReactNode
    placeholder: string
    disabled?: boolean
    onPrefixIconClick?: () => void
    onSuffixIconClick?: () => void
    type?: React.HTMLInputTypeAttribute
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    value?: string
    suffixText?: string
}

const InputField: React.FC<Props> = ({
    onPrefixIconClick,
    onSuffixIconClick,
    suffixIcon,
    prefixIcon,
    placeholder,
    type = 'text',
    onChange,
    disabled = false,
    value,
    suffixText,

}) => {
    return (
        <div
            className={`border rounded-md px-3 py-1 inline-flex gap-2 items-center ring-1 ${
                disabled
                    ? 'ring-gray-200 outline-none bg-gray-100'
                    : 'focus-within:ring-2 focus-within:ring-orange-500 focus-within:outline-orange-200 ring-gray-200 bg-gray-50'
            }`}
        >
            {prefixIcon && (
                <span
                    onClick={disabled ? undefined : onPrefixIconClick}
                    className={`cursor-pointer btn ${disabled ? 'cursor-not-allowed' : ''}`}
                >
                    {prefixIcon}
                </span>
            )}
            <input
                onChange={onChange}
                placeholder={placeholder}
                type={type}
                value={value}
                disabled={disabled}
                onWheel={(e) => e.currentTarget.blur()}
                className={`border-none font-normal focus:outline-none ${
                    disabled ? 'bg-gray-100 cursor-not-allowed text-gray-500' : 'bg-gray-50'
                }`}
            />
            {suffixIcon && (
                <span
                    onClick={disabled ? undefined : onSuffixIconClick}
                    className={`cursor-pointer btn ${disabled ? 'cursor-not-allowed' : ''}`}
                >
                    {suffixIcon}
                </span>
            )}
            {suffixText && (
                <span className={`text-sm ${disabled ? 'text-gray-500' : 'text-gray-700'}`}>{suffixText}</span>
            )}
        </div>
    )
}

export default InputField
