import { getClient } from '../configs/axios_configs'
import { defineCancelApiObject } from '../configs/axios_utils'

export const AddOnAPI = {
    update_addon: async function (cancel = false, plan_id: string, payload: any) {
        const client = await getClient()
        const envId = localStorage.getItem('env_id')
        const response = await client!.request({
            method: 'PUT',
            url: `/gw/plans/${plan_id}`,
            headers: {
                'X-Environment-ID': envId,
                'Content-Type': 'application/json',
            },
            data: payload,
            signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
        })
        const result: boolean = response.status === 200 ? true : false
        return result
    },
    update: async function (cancel = false, priceId: string, payload: any) {
        const client = await getClient()
        const envId = localStorage.getItem('env_id')
        const response = await client!.request({
            method: 'PUT',
            url: `/gw/prices/${priceId}`,
            headers: {
                'X-Environment-ID': envId,
                'Content-Type': 'application/json',
            },
            data: payload,
            signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
        })
        const result: boolean = response.status === 200 ? true : false
        return result
    },
    create: async function (cancel = false, payload: any) {
        try {
            const client = await getClient()
            const envId = localStorage.getItem('env_id')
            const response = await client!.request({
                method: 'POST',
                url: `/gw/prices/create`,
                headers: {
                    'X-Environment-ID': envId,
                    'Content-Type': 'application/json',
                },
                data: payload,
                signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
            })

            const result: boolean = response.status === 201 ? true : false
            return result
        } catch (error) {
            console.error('An error occurred:', error)
            return false
        }
    },
}

const cancelApiObject = defineCancelApiObject(AddOnAPI)
