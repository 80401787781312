import { Box, Switch, Typography } from '@mui/material'

interface FlexSwitchProps {
    title: string
    subTitle: string
    onChange: (value: boolean) => void
}
export default function FlexSwitch({ title, subTitle, onChange }: FlexSwitchProps) {
    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{ fontFamily: 'Inter', color: 'black', fontWeight: 'bold' }}>{title}</Typography>
                <Switch
                    color="success"
                    onChange={(event, checked) => {
                        console.log(event)
                        onChange(checked)
                    }}
                />
            </Box>
            <Typography
                sx={{
                    fontFamily: 'Inter',
                    fontWeight: '300',
                    fontStyle: 'italic',
                    fontSize: '14px',
                }}
            >
                {subTitle}
            </Typography>
        </div>
    )
}
