import { FeatureMapping } from '../../api/models/model_product_detail'
import { showFeatureDetail } from '../../utils/helper_functions_components'

interface QuotationCardProps {
    feature: FeatureMapping
}
export default function QuotationFeatureCard({ feature }: QuotationCardProps) {
    return (
        <div
            style={{
                fontSize: '16px',
                marginTop: '10px',
            }}
            className=" items-start  px-2 py-2.5 text-base font-medium leading-6 rounded border-red-500 border-solid bg-red-500 bg-opacity-10 border-l-[3px]"
        >
            {showFeatureDetail(feature)}
        </div>
    )
}
