import React, { ReactNode } from 'react'

interface Props {
    suffixIcon?: ReactNode
    prefixIcon?: ReactNode
    text: string
    onClick?: () => void
    bgColor?: string
    textColor?: string
}

const RectangleButton: React.FC<Props> = ({
    suffixIcon,
    prefixIcon,
    bgColor = '#E05D34',
    textColor = '#FFFFFF',
    text,
    onClick,
}) => {
    return (
        <button
            style={{ backgroundColor: bgColor, color: textColor }}
            onClick={onClick}
            className={'px-4 py-2 rounded-md shadow-sm btn flex gap-2 items-center'}
        >
            {suffixIcon}
            <span className={'font-medium'}>{text}</span>
            {prefixIcon}
        </button>
    )
}
export default RectangleButton
