import { FormBuilder } from '@/@ui-lib/molecules'
const formFields = [
    {
        name: 'product',
        label: 'Product',
        type: 'select',
        placeholder: 'Select Product',
        validation: {
            required: 'Product must be selected',
        },
        options: [
            { id: 'male', value: 'Male' },
            { id: 'female', value: 'Female' },
            { id: 'other', value: 'Other' },
        ],
    },
    {
        name: 'name',
        label: 'Name',
        type: 'text',
        placeholder: 'Enter plan name',
        validation: {
            required: 'plan name required',
        },
    },
    {
        name: 'slug',
        label: 'Slug',
        type: 'text',
        placeholder: 'Enter plan slug',
        validation: {
            required: 'plan name required',
        },
    },
    {
        name: 'age',
        label: 'Age',
        type: 'number',
        placeholder: 'Enter your age',
        validation: {
            pattern: {
                value: /^[Z0-9]+$/,
                message: 'values allowed only between 0-100',
            },
        },
    },
    {
        name: 'gender',
        label: 'Gender',
        type: 'select',
        placeholder: 'Select gender',
        multi_select: true,
        validation: {
            required: 'Gender is required',
        },
        options: [
            { id: 'male', value: 'Male' },
            { id: 'female', value: 'Female' },
            { id: 'other', value: 'Other' },
        ],
    },
]
const handleSubmit = () => console.log('Submitting')
const FormPage = () => (
    <div className=" mx-10 my-20">
        <FormBuilder fields={formFields} onSubmit={handleSubmit} />
    </div>
)
export default FormPage
