import { Outlet, useNavigate } from 'react-router-dom'
import { createContext, useEffect, useState } from 'react'
import { User } from '@supabase/supabase-js'
import Navbar from '../../components/common/navbar/navbar'
import { Divider } from '@mui/material'
import SideNavBar from '../../components/common/sidebar/sidebar'
import supabase from '../../utils/supabase'
import { EnvContextProvider } from '../../context/env_context'
import { EnvAPI } from '@/api/req/env_api'

interface HomePageContextType {
    user: User | null
}

export const HomePageContext = createContext<HomePageContextType>({
    user: null, // Default value
})

const HomePage: React.FC = () => {
    const [user, setUser] = useState<User | null>(null)

    const navigate = useNavigate()

    useEffect(() => {
        async function checkLogin() {
            const { data, error } = await supabase.auth.getUser()
            if (error) {
                navigate('/login')
            } else {
                EnvAPI.getAll().then(() => {
                    setUser(data?.user)
                })
            }
        }

        checkLogin()
    }, [])

    return (
        <div className="h-[98vh] overflow-y-hidden w-full bg-gray-50 flex flex-col">
            <HomePageContext.Provider value={{ user }}>
                <Navbar />
            </HomePageContext.Provider>
            <EnvContextProvider>
                <div
                    style={{
                        display: 'flex',
                        flexGrow: 1,
                        overflow: 'hidden',
                    }}
                >
                    <SideNavBar />
                    <Divider orientation="vertical" flexItem />
                    <div className={' flex-grow overflow-y-auto'}>
                        <Outlet />
                    </div>
                </div>
            </EnvContextProvider>
        </div>
    )
}

export default HomePage
