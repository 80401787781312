import { CustomerAPI } from '@/api/req/customer_api'
import FlexColors from '@/utils/colors'
import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Check, ChevronDown } from 'lucide-react'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import FlexButton from '@/components/common/button/button.tsx'
import { ModelFeature, ModelSnackbar } from '@/pages/sales/QuotationPage.tsx'
import CustomerDrawer from '../customer/CustomerDrawer'

type CustomerDropDownProps = {
    selectedValue: { id: string; name: string; email: string } | undefined
    onSelect(value: { id: string; name: string; email: string }): void
    setSelectedCustomer: React.Dispatch<React.SetStateAction<{ id: string; name: string; email: string } | undefined>>
    setSnackBarInfo: React.Dispatch<React.SetStateAction<ModelSnackbar>>
    setAlertOpen: React.Dispatch<React.SetStateAction<boolean>>
    formValue: FormModel
    onCreate: () => void
    setFormValue: React.Dispatch<React.SetStateAction<FormModel>>
}

export interface FormModel {
    customerName: string
    customerEmail: string
    searchFeatureText: string
    feature: ModelFeature[]
}

export default function CustomerDropdown({
    selectedValue,
    onSelect,
    setSelectedCustomer,
    setAlertOpen,
    onCreate,
    setSnackBarInfo,
}: CustomerDropDownProps) {
    const [data, setData] = useState<{ id: string; name: string; email: string }[]>([])

    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [showDrawer, setShowDrawer] = useState(false)

    useEffect(() => {
        getAllCustomers()
    }, [])

    const getAllCustomers = () => {
        setLoading(true)
        CustomerAPI.getAll()
            .then((response) => {
                setData(
                    response.map((value) => ({
                        id: value.id,
                        name: value.name,
                        email: value.email,
                    }))
                )
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }
    const onClose = () => {
        setShowDrawer(false)
    }

    return (
        <div>
            <CustomerDrawer
                onSuccess={(response) => {
                    setSelectedCustomer(response)
                    setShowDrawer(false)
                    getAllCustomers()
                    onCreate()
                }}
                onClose={onClose}
                onError={() => {
                    setSnackBarInfo({
                        title: 'Error creating customer',
                        isError: true,
                    })
                    setAlertOpen(true)
                    setShowDrawer(false)
                }}
                drawerVisibility={showDrawer}
            />
            {loading && (
                <div className="flex justify-start items-center">
                    <CircularProgress style={{ color: FlexColors.primary, width: 20, height: 20 }} />
                </div>
            )}

            {!loading && (
                <div>
                    <Popover open={open} onOpenChange={setOpen}>
                        <PopoverTrigger asChild>
                            <Button
                                variant="outline"
                                role="combobox"
                                aria-expanded={open}
                                className="min-w-[200px] text-base justify-between"
                            >
                                {selectedValue ? selectedValue.name : 'Select Customer'}
                                <ChevronDown className="ml-2 shrink-0 font-bold text-orange-600" />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="min-w-[200px] p-0">
                            <Command>
                                <CommandInput placeholder="Search Customer..." />
                                <CommandList>
                                    <CommandEmpty>Not found</CommandEmpty>
                                    <CommandGroup>
                                        {data.map((framework) => (
                                            <CommandItem
                                                key={framework.id}
                                                value={framework.name}
                                                onSelect={(currentValue) => {
                                                    onSelect(data.find((f) => f.name === currentValue)!)
                                                    setOpen(false)
                                                }}
                                            >
                                                <Check
                                                    className={cn(
                                                        'mr-2 h-4 w-4',
                                                        selectedValue?.name === framework.name
                                                            ? 'opacity-100'
                                                            : 'opacity-0'
                                                    )}
                                                />
                                                <p>{framework.name}</p>
                                            </CommandItem>
                                        ))}
                                    </CommandGroup>
                                </CommandList>
                                <div className={'p-2'}>
                                    <FlexButton
                                        text="+ Customer"
                                        textSize={16}
                                        height={40}
                                        isLoading={false}
                                        onClick={() => {
                                            setOpen(false)
                                            setShowDrawer((prev) => !prev)
                                        }}
                                    />
                                </div>
                            </Command>
                        </PopoverContent>
                    </Popover>
                </div>
            )}
        </div>
    )
}
