import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import supabase from '../utils/supabase'
import FlexColors from '@/utils/colors'
import { CircularProgress } from '@mui/material'

const DefaultRoute: React.FC = () => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null)

    useEffect(() => {
        const checkLogin = async () => {
            const {
                data: { session },
            } = await supabase.auth.getSession()
            setIsLoggedIn(!!session)
        }
        checkLogin()
    }, [])

    if (isLoggedIn === null) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    height: '100%',
                    width: '100%',
                }}
            >
                <CircularProgress sx={{ color: FlexColors.primary }} />
            </div>
        ) // or a spinner
    }

    return isLoggedIn ? <Navigate to="/quotes" /> : <Navigate to="/login" />
}

export default DefaultRoute
