import { forwardRef, useState } from 'react'

interface TabBuilderProps {
    tabs: ModelTabItem[]
}

const TabBuilder = forwardRef<HTMLDivElement, TabBuilderProps>(({ tabs }, ref) => {
    const [activeIndex, setActiveIndex] = useState<number>(0)

    return (
        <div className="tab w-full">
            <div ref={ref} className="flex border-b border-gray-300">
                {tabs.map((tab, index) => (
                    <div
                        key={tab.label}
                        className={`px-4 py-2 cursor-pointer ${
                            activeIndex === index ? 'text-orange-600 border-b-2 border-orange-600' : 'text-gray-600'
                        } ${tab.disabled ? 'cursor-not-allowed opacity-50' : ''}`}
                        onClick={() => {
                            if (!tab.disabled) {
                                setActiveIndex(index)
                            }
                        }}
                    >
                        {tab.label}
                    </div>
                ))}
            </div>
            <div className="p-4">{tabs[activeIndex].component}</div>
        </div>
    )
})

export default TabBuilder
