import { forwardRef } from 'react'
import Select from 'react-select'

export interface SelectOption {
    id: string
    value: string
}

interface SelectProps {
    label: string
    name: string
    placeholder?: string
    field: any
    errors?: any
    disabled?: boolean
    required?: boolean
    multiple?: boolean
    options: SelectOption[]
}

const FlexSelect = forwardRef<any, SelectProps>(
    (
        {
            label,
            name,
            placeholder = 'Select an option',
            field,
            errors = {},
            options,
            required = false,
            disabled = false,
        },
        ref
    ) => {
        const errorMessage = errors[name]?.message

        const customStyles = {
            control: (styles: any, { isFocused }: any) => ({
                ...styles,
                borderColor: isFocused ? 'rgba(249, 115, 22, 1)' : styles.borderColor,
                boxShadow: isFocused ? '0 0 0 1px rgba(249, 115, 22, 1)' : styles.boxShadow,
                '&:hover': { borderColor: 'rgba(249, 115, 22, 1)' },
                borderRadius: '0.375rem',
                minHeight: '2.2rem',
                fontSize: '0.9rem',
            }),
            menu: (styles: any) => ({
                ...styles,
                borderRadius: '0.375rem',
                fontSize: '0.9rem',
            }),
            option: (styles: any, { isFocused, isSelected }: any) => ({
                ...styles,
                backgroundColor: isFocused ? 'rgba(249, 115, 22, 0.1)' : isSelected ? 'rgba(249, 115, 22, 0.3)' : null,
                color: isSelected ? 'rgba(249, 115, 22, 1)' : styles.color,
                padding: '10px',
            }),
        }

        const formattedOptions = options.map((opt) => ({ label: opt.value, value: opt.id }))

        return (
            <div className="mb-4">
                <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
                    {label}
                    {required && <span className="text-red-500 ml-1">*</span>}
                </label>
                <Select
                    ref={ref}
                    placeholder={placeholder}
                    options={formattedOptions}
                    isDisabled={disabled}
                    styles={customStyles}
                    onChange={(option: any) => field.onChange(option ? option.value : null)}
                    value={formattedOptions.find((opt) => opt.value === field.value) || null}
                    classNamePrefix="react-select"
                />
                {errorMessage && <span className="text-red-500 text-sm mt-1">{errorMessage}</span>}
            </div>
        )
    }
)

FlexSelect.displayName = 'FlexSelect'

export default FlexSelect
