import { GrDocument } from 'react-icons/gr'
import { PlanConfig } from '../CustomerSubscription'
import { formatNumberUS } from '@/utils/utility_functions'
import Spacer from '@/components/common/util/spacer'

interface SubscriptionPreviewProps {
    productName?: string
    planName?: string
    fixedDiscount: number // Now in percentage (0 to 100)
    recurringDiscount: number // Now in percentage (0 to 100)
    planConfig: PlanConfig[]
    addonConfig: PlanConfig[]
    startDate: Date
}

const getDaySuffix = (day: number): string => {
    if (day < 1 || day > 31) {
        throw new Error('Invalid day. It should be between 1 and 31.')
    }

    if (day % 10 === 1 && day !== 11) {
        return day + 'st'
    } else if (day % 10 === 2 && day !== 12) {
        return day + 'nd'
    } else if (day % 10 === 3 && day !== 13) {
        return day + 'rd'
    } else {
        return day + 'th'
    }
}
const calculateDiscount = (discount: number, price: number) => {
    return (discount / 100) * price
}

const calculateTieredPrice = (config: PlanConfig) => {
    if (config.type === 'FLAT') return config.quantity * config.price
    const quantity = config.quantity

    let total = 0
    for (const tier of config.tiered_price!) {
        const upTo = tier.up_to === 999999999 ? Infinity : Number(tier.up_to)
        if (quantity > upTo) {
            total += upTo * tier.price
        } else {
            total += quantity * tier.price
            break
        }
    }
    return total
}

const getPriceForAddon = (config: PlanConfig) => {
    if (config.type === 'FLAT') return config.price
    const quantity = config.quantity

    for (const tier of config.tiered_price!) {
        const upTo = tier.up_to === 999999999 ? Infinity : Number(tier.up_to)
        if (quantity <= upTo) {
            return tier.price
        }
    }

    return 0
}

export default function SubscriptionPreview({
    planConfig,
    addonConfig,
    productName,
    planName,
    fixedDiscount,
    recurringDiscount,
    startDate,
}: SubscriptionPreviewProps) {
    let totalPrice = planConfig.reduce((total, plan) => total + plan.quantity * plan.price, 0)
    const basecharge = planConfig?.at(0)!.price
    const fixedDiscountAmount = calculateDiscount(fixedDiscount, basecharge)
    const recurringDiscountAmount = calculateDiscount(recurringDiscount, totalPrice - basecharge)

    const recurringPriceAddon = addonConfig.reduce(
        (total, addon) => total + (addon.type == 'FLAT' ? addon.quantity * addon.price : calculateTieredPrice(addon)),
        0
    )
    totalPrice += recurringPriceAddon

    const discountedTotalPrice = totalPrice - fixedDiscountAmount - recurringDiscountAmount

    return (
        <div>
            <div className="px-5 py-5 border rounded-lg border-gray-600 fill-background bg-gray-200">
                <div className="flex text-xl gap-5 w-full content-center">
                    <GrDocument className="h-6 w-6" />
                    <span className="text-xl font-bold">Subscription Preview</span>
                </div>
                <Spacer height={10} />
                <span className="text-sm font-semibold">Recurring Charges</span>
                <div className="flex gap-1 mt-3 text-sm">
                    <span>{productName}/</span>
                    <span>{planName}</span>
                </div>
                {planConfig.map((plan) =>
                    plan.quantity > 0 ? (
                        <div key={plan.name} className="mt-2 flex text-sm justify-between">
                            <div className="text-gray-800">{plan.name}</div>

                            <div className="text-gray-700">
                                {plan.quantity > 1 ? (
                                    <div className="flex items-center">
                                        <span>
                                            {plan.quantity} x ${formatNumberUS(plan.price)}
                                        </span>{' '}
                                        =<span className="ml-2">${formatNumberUS(plan.quantity * plan.price)}</span>
                                    </div>
                                ) : (
                                    <div>${formatNumberUS(plan.price)}</div>
                                )}
                            </div>
                        </div>
                    ) : null
                )}
                <Spacer height={10} />
                {addonConfig.length > 0 && (
                    <div className="flex items-center">
                        <span className="font-semibold">Add-ons</span>
                        <div className="flex-grow ml-2 border-t border-gray-900"></div>
                    </div>
                )}
                {addonConfig.map((plan) =>
                    plan.quantity > 0 ? (
                        <div key={plan.name} className="mt-2 flex text-sm justify-between">
                            <div className="text-gray-800">{plan.name}</div>

                            <div className="text-gray-700">
                                {plan.quantity > 1 ? (
                                    <div className="flex items-center">
                                        <span>
                                            {plan.quantity} x ${formatNumberUS(getPriceForAddon(plan))}
                                        </span>{' '}
                                        =<span className="ml-2">${formatNumberUS(calculateTieredPrice(plan))}</span>
                                    </div>
                                ) : (
                                    <div>${formatNumberUS(plan.price)}</div>
                                )}
                            </div>
                        </div>
                    ) : null
                )}
                {fixedDiscount > 0 && (
                    <div className={' text-sm  flex w-full justify-between'}>
                        <span>Fixed Discount ({fixedDiscount}%)</span>
                        <span>-${formatNumberUS(fixedDiscountAmount)}</span>
                    </div>
                )}{' '}
                {recurringDiscount > 0 && (
                    <div className={' text-sm  flex w-full justify-between'}>
                        <span>Reccuring Discount ({recurringDiscount}%)</span>
                        <span>-${formatNumberUS(recurringDiscountAmount)}</span>
                    </div>
                )}
                {(planConfig.length > 0 || addonConfig.length > 0) && (
                    <div>
                        <div className="mt-1 flex-grow border-t border-gray-900"></div>
                        <div className="mt-1 flex justify-between text-l font-bold">
                            <span>Total</span>
                            <span>${formatNumberUS(discountedTotalPrice)}</span>
                        </div>
                    </div>
                )}
            </div>

            <Spacer height={10} />

            <div className="px-10 py-5 border rounded-lg border-orange-600 fill-background bg-orange-100">
                <span>
                    The customer will be billed{' '}
                    <span className="font-bold text-primary">
                        ${formatNumberUS(totalPrice - recurringDiscountAmount - basecharge)}
                    </span>{' '}
                    for this subscription every month on the{' '}
                    <span className={'text-primary font-semibold'}>{getDaySuffix(startDate.getDate())}</span>
                </span>
            </div>
        </div>
    )
}
