import { Container, CircularProgress } from '@mui/material'
import { Auth } from '@supabase/auth-ui-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import supabase from '../../utils/supabase'
import customTheme from '../../utils/customTheme'
import Logo from '../../components/common/logo/logo'
import { initializeClient } from '../../api/configs/axios_configs'
import { UserAPI } from '@/api/req/user_api'
import { useUser } from '@/context/UserContext'
import FlexColors from '@/utils/colors'

const AuthPage: React.FC = () => {
    const navigate = useNavigate()
    const userContext = useUser()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const { data: authListener } = supabase.auth.onAuthStateChange((event) => {
            if (event === 'SIGNED_IN') {
                setLoading(true)
                initializeClient().then(() => {
                    UserAPI.me(false, {})
                        .then((value) => {
                            localStorage.setItem('user', JSON.stringify(value))
                            userContext.setUser(value)
                            setLoading(false)
                            navigate('/')
                        })
                        .catch((err: any) => {
                            console.error('Error fetching user data:', err)
                            setLoading(false)
                            navigate('/login')
                        })
                })
            }
        })

        return () => {
            authListener.subscription.unsubscribe()
        }
    }, [])

    return (
        <div className="flex items-center justify-center font-inter min-h-screen">
            {loading ? (
                <CircularProgress sx={{ color: FlexColors.primary }} />
            ) : (
                <Container maxWidth="sm">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '50px',
                        }}
                    >
                        <Logo />
                    </div>

                    <div className="bg-zinc-50 rounded-xl shadow-sm border border-zinc-300 px-11 py-12">
                        <Auth
                            supabaseClient={supabase}
                            socialLayout="vertical"
                            appearance={{
                                theme: customTheme,
                                style: {
                                    button: {
                                        borderColor: 'hsla(0, 0%, 87%, 1)',
                                        borderWidth: '1px',
                                    },
                                    input: {
                                        borderColor: 'hsla(0, 0%, 87%, 1)',
                                        borderWidth: '1px',
                                    },
                                    anchor: {
                                        fontSize: '14px',
                                        alignContent: 'left',
                                    },
                                    divider: {
                                        backgroundColor: 'hsla(0, 0%, 87%, 1)',
                                    },
                                },
                            }}
                            localization={{
                                variables: {
                                    sign_in: {
                                        email_label: 'Email',
                                        email_input_placeholder: 'abc@xyz.com',
                                        password_label: 'Password',
                                        password_input_placeholder: 'Enter your password',
                                        button_label: 'Login',
                                        loading_button_label: 'Loading...',
                                        social_provider_text: 'Sign in with {{provider}}',
                                    },
                                    sign_up: {
                                        email_label: 'Email',
                                        email_input_placeholder: 'abc@xyz.com',
                                        password_label: 'Password',
                                        password_input_placeholder: 'Enter your password',
                                        social_provider_text: 'Sign in with {{provider}}',
                                    },
                                },
                            }}
                            redirectTo={window.location.origin}
                            providers={['google']}
                        />
                    </div>
                </Container>
            )}
        </div>
    )
}

export default AuthPage
