import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'

type AddOnPageDropDownProps = {
    label: string
    data: string[]
    disabled?: boolean
    selectedValue: string
    onSelect(value: string): void
}
export default function AddOnPageDropDown({
    label,
    data,
    selectedValue,
    disabled = false,
    onSelect,
}: AddOnPageDropDownProps) {
    return (
        <div>
            <h3 className="font-medium">
                {label}
                <span className="text-red-600">*</span>
            </h3>
            <Select
                disabled={disabled}
                value={selectedValue}
                onValueChange={(selectedOption) => {
                    onSelect(selectedOption)
                }}
            >
                <SelectTrigger className="w-[300px]">
                    <SelectValue placeholder="Theme" />
                </SelectTrigger>
                <SelectContent>
                    {data.map((value, index) => (
                        <SelectItem key={index} value={value}>
                            {value}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    )
}
