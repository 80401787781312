import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import { HomePageContext } from '../../../pages/home/home'

export default function UserProfile() {
    const { user } = useContext(HomePageContext)
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'blue',
                    borderRadius: '10px',
                    padding: '10px',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '20px',
                        color: 'white',
                        fontStyle: 'bold',
                    }}
                >
                    {user?.email?.toUpperCase()[0]}
                </Typography>
            </Box>
            <Typography
                variant="h4"
                sx={{
                    fontSize: '18px',
                    color: 'black',
                    marginLeft: '10px',
                }}
            >
                {user?.email}
            </Typography>
        </Box>
    )
}
