import { ModelCustomer } from '@/api/models/model_customer'
import { ModelProduct } from '@/api/models/model_product'
import { Plan, Price, PriceTier } from '@/api/models/model_product_detail'
import { CustomerAPI } from '@/api/req/customer_api'
import { ProductAPI } from '@/api/req/product_api'
import { ProductDetailAPI } from '@/api/req/product_detail_api'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomerDetail from './widgets/CustomerDetail'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import SubscriptionPreview from './widgets/SubscriptionPreview'
import Spacer from '@/components/common/util/spacer'
import PlanConfigTable from './widgets/PlanConfigTable'
import FlexDatePicker from '@/components/common/date_picker/FlexDatePicker'
import FlexButton from '@/components/common/button/FlexButton'
import { convertToPlanConfig, covertAddonPriceConfig } from '@/utils/utility_functions'
import SearchableSelect from './widgets/SearchableDropdown'
import './customerstyle.css'
import { ModelGuardRail } from '@/api/models/model_guardrail'
import DiscountSection from '@/pages/sales/DiscountSection.tsx'

export interface PlanConfig {
    name: string
    quantity: number
    isEditable: boolean
    price: number
    tiered_price?: PriceTier[]
    billingPeriod: string
    type?: string
    unit: string | undefined
    price_id: string
}

function showMonthlyAnuualDropdwn(plan: Plan): boolean {
    if (!plan.prices || plan.prices.length === 0) {
        return false
    }

    const allPricesHaveBoth = plan.prices.every(
        (price) => price.monthly_price !== undefined && price.annual_price !== undefined
    )

    return allPricesHaveBoth
}

export default function CustomerSubscription() {
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const customerId = searchParams.get('id')

    const [customerDetails, setCustomerDetails] = useState<ModelCustomer | undefined>()
    const [products, setProducts] = useState<ModelProduct[] | undefined>()
    const [selectedProduct, setSelectedProduct] = useState<ModelProduct | undefined>()
    const [billingPeriod, setBillingPeriod] = useState<string>('Monthly')
    const [plans, setPlans] = useState<Plan[] | undefined>()
    const [addons, setAddons] = useState<Plan[]>([])
    const [selectedAddonConfig, setSelectedAddonConfig] = useState<PlanConfig[]>([])
    const [selectedPlan, setSelectedPlan] = useState<Plan | undefined>()

    const [startDate, setStartDate] = useState<Date>(new Date(Date.now()))
    const [planConfigs, setPlanConfigs] = useState<PlanConfig[]>([])
    const [guardRails, setGuardRails] = useState<ModelGuardRail[]>([])

    const [fixedDiscount, setFixedDiscount] = useState<number>(0)
    const [recurringDiscount, setRecurringDiscount] = useState<number>(0)

    useEffect(() => {
        if (customerId) {
            fetchCustomerDetails(customerId)
            fetchAllProducts()
        }
    }, [])

    const fetchGuardRails = (productId: string) => {
        ProductAPI.getProductGuardRail(productId).then((guardRail) => {
            setGuardRails(guardRail)
        })
    }
    const fetchCustomerDetails = (customerId: string) => {
        CustomerAPI.getCustomerById(false, customerId).then((response) => {
            if (response) {
                setCustomerDetails(response)
            }
        })
    }

    const fetchAllProducts = () => {
        ProductAPI.getAll(false).then((response) => setProducts(response))
    }

    const onProductSelected = (product: ModelProduct) => {
        fetchProductDetails(product.id)
    }

    const fetchProductDetails = (productId: string) => {
        ProductDetailAPI.getAll(productId).then((product) => {
            setPlans(product!.plans)
            setAddons(product!.add_ons)
        })
    }

    const handleDelete = (configName: string) => {
        const addonConfig = selectedAddonConfig?.filter((config) => config.name !== configName)
        setSelectedAddonConfig(addonConfig)
    }
    const transformPrice = (prices: Price[], billingPeriod: string) => {
        const transformedPrice = convertToPlanConfig(prices, billingPeriod.toUpperCase())
        setPlanConfigs(transformedPrice)
    }

    const onCancelClicked = () => {
        navigate(-1)
    }

    const onSubmitClicked = () => {
        const baseData = {
            billing_provider: 'internal',
            customer_id: customerId,
            subscription_status: 'ACTIVE',
            billing_anchor: startDate.toISOString(),
            start_date_time: startDate.toISOString(),
        }

        const payload = {
            ...baseData,
            line_items: [
                ...planConfigs.map((plan) => ({
                    type: plan.name.toLowerCase(),
                    product_id: selectedProduct?.id,
                    plan_id: selectedPlan?.id,
                    plan_version: 'v1',
                    price_id: plan.price_id,
                    quantity: plan.quantity,
                })),
            ],
        }

        CustomerAPI.createSubscription(false, payload).then((response) => {
            if (response) {
                onCancelClicked()
            }
        })
    }

    return (
        <div className="mx-5 h-full flex scrollbar-hidden">
            <div className="w-[70%] mt-10 overflow-y-auto pr-5 scrollbar-hidden">
                <div className="mb-5 text-2xl font-bold text-orange-600">
                    <span className="block text-left">Create Subscription</span>
                </div>

                {customerDetails && <CustomerDetail customer={customerDetails!} />}
                <Spacer height={20} />

                {products && (
                    <div>
                        <h1 className={'mb-1 text-base font-semibold text-primary'}>Products</h1>
                        <Select
                            value={selectedProduct?.name}
                            onValueChange={(selectedOption) => {
                                const selectedProduct = products?.find((product) => product.name === selectedOption)
                                setSelectedProduct(selectedProduct)
                                onProductSelected(selectedProduct!)
                                fetchGuardRails(selectedProduct!.id!)
                            }}
                        >
                            <SelectTrigger className="w-full">
                                <SelectValue placeholder="Select product" />
                            </SelectTrigger>
                            <SelectContent>
                                {products?.map((value, index) => (
                                    <SelectItem key={index} value={value.name}>
                                        {value.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                )}

                <Spacer height={20} />
                {plans && plans.length > 0 && (
                    <div>
                        <h1 className={'mb-1 text-base font-semibold text-primary'}>Plans</h1>
                        <Select
                            value={selectedPlan?.name}
                            onValueChange={(selectedOption) => {
                                const selectedPlan = plans?.find((plan) => plan.name === selectedOption)
                                setSelectedPlan(selectedPlan)
                                transformPrice(selectedPlan!.prices!, billingPeriod)
                            }}
                        >
                            <SelectTrigger className="w-full">
                                <SelectValue placeholder="Select plan" />
                            </SelectTrigger>
                            <SelectContent>
                                {plans?.map((value, index) => (
                                    <SelectItem key={index} value={value.name}>
                                        {value.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                )}

                <Spacer height={20} />
                {selectedPlan && planConfigs.length > 0 && showMonthlyAnuualDropdwn(selectedPlan) && (
                    <div>
                        <h1 className={'mb-1 text-base font-semibold text-primary'}>Billing Period</h1>
                        {/* <FlexDropdown */}
                        {/*     options={['Monthly', 'Annually']} */}
                        {/*     onSelect={(value) => { */}
                        {/*         setBillingPeriod(value) */}
                        {/*         transformPrice(selectedPlan!.prices!, value) */}
                        {/*     }} */}
                        {/* /> */}
                        <Select
                            value={billingPeriod}
                            onValueChange={(value) => {
                                setBillingPeriod(value)
                                transformPrice(selectedPlan!.prices!, value)
                            }}
                        >
                            <SelectTrigger className="w-full">
                                <SelectValue placeholder="Select Billing Period" />
                            </SelectTrigger>
                            <SelectContent>
                                {['Monthly', 'Annually']?.map((value, index) => (
                                    <SelectItem key={index} value={value}>
                                        {value}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                )}

                <Spacer height={20} />
                {selectedPlan && planConfigs.length >= 0 && (
                    <div>
                        <h1 className={'mb-1 text-base font-semibold text-primary'}>Charges</h1>
                        <PlanConfigTable
                            planConfig={planConfigs}
                            onValueChanged={(key, value) => {
                                setPlanConfigs((prevConfigs) =>
                                    prevConfigs.map((config) =>
                                        config.name === key ? { ...config, quantity: parseInt(value) } : config
                                    )
                                )
                            }}
                        />
                    </div>
                )}

                <Spacer height={20} />
                {selectedPlan && (
                    <div>
                        <h1 className={'mb-1 text-base font-semibold text-primary'}>Addons</h1>
                        <SearchableSelect
                            onSelectPlan={(value) => {
                                setSelectedAddonConfig([
                                    ...selectedAddonConfig,
                                    covertAddonPriceConfig(value?.prices ?? [], value!.name!, billingPeriod),
                                ])
                            }}
                            plans={addons!}
                            selectedPlans={selectedAddonConfig}
                        />
                    </div>
                )}

                <Spacer height={20} />
                {selectedAddonConfig && selectedAddonConfig.length > 0 && (
                    <PlanConfigTable
                        planConfig={selectedAddonConfig}
                        onValueChanged={(key, value) => {
                            setSelectedAddonConfig((prevConfigs) =>
                                prevConfigs.map((config) =>
                                    config.name === key ? { ...config, quantity: parseInt(value) } : config
                                )
                            )
                        }}
                        onDelete={handleDelete}
                    />
                )}

                <Spacer height={20} />
                {selectedPlan && (
                    <FlexDatePicker
                        label="Start Date"
                        onDatePicked={(value) => {
                            setStartDate(new Date(value!.year, value!.month - 1, value!.day))
                        }}
                        selectedDate={{
                            day: startDate.getDate(),
                            month: startDate.getMonth() + 1,
                            year: startDate.getFullYear(),
                        }}
                    />
                )}

                <Spacer height={20} />
                {selectedPlan && (
                    <DiscountSection
                        label={'Add Discount'}
                        modelGuardRail={guardRails}
                        onDiscountApplied={(fixed, recurring) => {
                            setFixedDiscount(fixed)
                            setRecurringDiscount(recurring)
                        }}
                    />
                )}
                <Spacer height={60} />

                {selectedPlan && (
                    <div className="flex gap-5">
                        <FlexButton type="outlined" onClick={onCancelClicked} label="Cancel" />
                        <FlexButton type="filled" onClick={onSubmitClicked} label="Create" />
                    </div>
                )}

                <Spacer height={20} />
            </div>

            <div className="w-[30%] h-full flex justify-center items-center">
                {planConfigs.length > 0 && (
                    <SubscriptionPreview
                        planConfig={planConfigs}
                        planName={selectedPlan?.name}
                        addonConfig={selectedAddonConfig}
                        fixedDiscount={fixedDiscount}
                        recurringDiscount={recurringDiscount}
                        productName={selectedProduct?.name}
                        startDate={startDate}
                    />
                )}
            </div>
            {/* { */}
            {/*     <Dialog */}
            {/*         open={openDiscount} */}
            {/*         onClose={() => { */}
            {/*             setOpenDiscount(false) */}
            {/*         }} */}
            {/*     > */}
            {/*         <DialogContent sx={{ width: 500 }}> */}
            {/*             <DiscountInput */}
            {/*                 label={'Add Discount'} */}
            {/*                 modelGuardRail={guardRails} */}
            {/*                 onDiscountApplied={(fixed, recurring) => { */}
            {/*                     setFixedDiscount(fixed) */}
            {/*                     setRecurringDiscount(recurring) */}
            {/*                 }} */}
            {/*                 onClose={() => setOpenDiscount(false)} */}
            {/*             /> */}
            {/*         </DialogContent> */}
            {/*     </Dialog> */}
            {/* } */}
        </div>
    )
}
