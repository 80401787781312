import { CustomerAPI } from '@/api/req/customer_api'
import FlexButton from '@/components/common/button/button'
import CopyButton from '@/components/common/copy_text/copy_text'
import Spacer from '@/components/common/util/spacer'
import FlexColors from '@/utils/colors'
import { Close, ContactSupport, ThumbDown, ThumbDownOffAlt, ThumbUp, ThumbUpOffAlt } from '@mui/icons-material'
import {
    Alert,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    IconButton,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material'
import { useState } from 'react'

interface LikeDislikeButtonProps {
    requestId: string
    productId: string
}

export default function LikeDislikeButton({ requestId, productId }: LikeDislikeButtonProps) {
    const [viewDialog, setViewDialog] = useState(false)
    const [text, setText] = useState('')
    const [isLiked, setIsLiked] = useState(false)
    const [isDisliked, setIsDisliked] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false)
    const [snackBarInfo, setSnackBarInfo] = useState<{
        title: string
        isError: boolean
    }>()
    const handleClose = () => {
        setViewDialog(false)
    }

    const countWords = (text: string) => {
        return text.trim().split(/\s+/).length
    }

    const handleSubmit = (isLike: boolean) => {
        const payload = {
            action: isLike ? 'LIKE' : 'DISLIKE',
            quotation_id: requestId,
            remarks: isLike ? null : text,
        }

        CustomerAPI.postQuotationFeedback(false, payload, productId).then((response) => {
            if (response) {
                if (isLike) {
                    setIsLiked(true)
                    setIsDisliked(false)
                } else {
                    setIsDisliked(true)
                    setIsLiked(false)
                }
                setSnackBarInfo({
                    title: 'Thankyou for your valuable response',
                    isError: false,
                })
            } else {
                if (isLike) setIsLiked(false)
                else setIsDisliked(true)
                setSnackBarInfo({
                    title: 'Thankyou for your valuable response',
                    isError: false,
                })
            }
            setAlertOpen(true)
        })
        handleClose()
    }

    return (
        <div>
            <IconButton sx={{ color: FlexColors.primary }} onClick={() => handleSubmit(true)}>
                {isLiked ? <ThumbUp /> : <ThumbUpOffAlt />}
            </IconButton>
            <IconButton
                sx={{ color: FlexColors.primary }}
                onClick={() => {
                    setViewDialog(true)
                }}
            >
                {isDisliked ? <ThumbDown /> : <ThumbDownOffAlt />}
            </IconButton>
            <Dialog open={viewDialog} onClose={handleClose}>
                <DialogTitle>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <ContactSupport sx={{ color: FlexColors.primary }} />
                            <Spacer width={10} />
                            <Typography
                                fontSize={20}
                                fontFamily={'Open Sans'}
                                color={FlexColors.primary}
                                fontWeight={700}
                            >
                                Leave a comment
                            </Typography>
                        </div>
                        <div>
                            <IconButton onClick={handleClose}>
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent sx={{ width: 500 }}>
                    <FormControl fullWidth>
                        <Typography fontSize={14} fontWeight={600}>
                            How can we improve?
                        </Typography>
                        <TextField
                            id="filled-start-adornment"
                            autoFocus
                            margin="dense"
                            type="text"
                            placeholder="describe here"
                            multiline
                            minRows={6}
                            value={text}
                            InputProps={{
                                sx: {
                                    fontSize: '14px',
                                },
                            }}
                            onChange={(e) => setText(e.target.value)}
                        />
                        <FormHelperText style={{ textAlign: 'right' }}>{`${countWords(text)}/500`}</FormHelperText>
                    </FormControl>
                    <Spacer height={10} />
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                justifyContent: 'start',
                            }}
                        >
                            <Typography fontSize={12} fontWeight={500}>
                                Quotation Id
                            </Typography>
                            <CopyButton
                                textToCopy={requestId}
                                padding={0}
                                textSize={12}
                                textColor="gray"
                                iconColor={'gray'}
                                showBackground={false}
                            />
                        </div>
                        <FlexButton
                            onClick={() => handleSubmit(false)}
                            textSize={16}
                            height={30}
                            width={100}
                            text="Submit"
                        />
                    </div>
                </DialogContent>
            </Dialog>
            <Snackbar
                open={alertOpen}
                onDurationChange={() => setAlertOpen(false)}
                autoHideDuration={3000}
                onClose={() => setAlertOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    severity={snackBarInfo?.isError ? 'error' : 'success'}
                    sx={{ width: '100%' }}
                    onClose={() => setAlertOpen(false)}
                >
                    {snackBarInfo?.title}
                </Alert>
            </Snackbar>
        </div>
    )
}
