import { getClient } from '../configs/axios_configs'
import { defineCancelApiObject } from '../configs/axios_utils'
import { ModelProductDetail } from '../models/model_product_detail'

export const ProductDetailAPI = {
    getAll: async function (id: string, cancel = false) {
        const client = await getClient()
        const envId = localStorage.getItem('env_id')
        const response = await client!.get(`/gw/products/details/${id}`, {
            headers: {
                'X-Environment-ID': envId,
            },
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })
        const result: ModelProductDetail = response.data['data']
        return result
    },
}

const cancelApiObject = defineCancelApiObject(ProductDetailAPI)
