import { getClient } from '../configs/axios_configs'
import { defineCancelApiObject } from '../configs/axios_utils'
import { ModelGuardRail } from '../models/model_guardrail'
import { ModelProduct } from '../models/model_product'

export const ProductAPI = {
    getAll: async function (cancel = false) {
        const client = await getClient()
        const envId = localStorage.getItem('env_id')
        const response = await client!.get('/gw/products/', {
            headers: {
                'X-Environment-ID': envId,
            },
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })
        const result: ModelProduct[] = response.data['data']
        return result
    },
    getProductGuardRail: async function (id: string, cancel = false) {
        const client = await getClient()
        const envId = localStorage.getItem('env_id')
        const response = await client!.get(`/gw/guardrail/product/${id}`, {
            headers: {
                'X-Environment-ID': envId,
            },
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })
        const result: ModelGuardRail[] = response.data['data']
        return result
    },
}

const cancelApiObject = defineCancelApiObject(ProductAPI)
