import { TabBuilder } from '@/@ui-lib/molecules'
import Activity from './tabs/Activity'
import EntitlementSummary from './tabs/EntitlementSummary'
import EntitlementUsage from './tabs/EntitlementUsage'
import Overview from './tabs/Overview'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { ModelCustomer } from '@/api/models/model_customer'
import { CustomerAPI } from '@/api/req/customer_api'
import Spacer from '@/components/common/util/spacer'

export default function CustomerDetailPage() {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const customerId = searchParams.get('id')
    const [customerDetails, setCustomerDetails] = useState<ModelCustomer | undefined>()

    useEffect(() => {
        if (customerId) {
            fetchCustomerDetails(customerId)
        }
    }, [])

    const fetchCustomerDetails = (customerId: string) => {
        CustomerAPI.getCustomerById(false, customerId).then((response) => {
            if (response) {
                setCustomerDetails(response)
            }
        })
    }
    const tabs: ModelTabItem[] = [
        {
            label: 'Overview',
            component: <Overview customer={customerDetails!} />,
            disabled: false,
        },
        {
            label: 'Entitlement Usage',
            component: <EntitlementUsage />,
            disabled: false,
        },
        {
            label: 'Entitlement Summary',
            component: <EntitlementSummary />,
            disabled: true,
        },
        {
            label: 'Activity',
            component: <Activity />,
            disabled: true,
        },
    ]
    return (
        <div className="mx-5 h-full scrollbar-hidden">
            <Spacer height={20} />
            <div className="flex items-center mb-4">
                <div className="flex items-center justify-center w-12 h-12 bg-orange-500 text-white text-lg font-bold rounded-full mr-4">
                    {customerDetails?.name?.charAt(0).toUpperCase()}
                </div>
                <div>
                    <div className="text-sm text-gray-500 ">{'Customer'}</div>
                    <div className="text-xl font-semibold">{customerDetails?.slug || 'Customer Slug'}</div>
                </div>
            </div>
            <Spacer height={5} />
            {customerDetails && <TabBuilder tabs={tabs} />}
        </div>
    )
}
