import { ReactNode, Ref, useState } from 'react'
import { InputAdornment, TextField, Typography } from '@mui/material'
import Spacer from '../util/spacer'
import { ExpandMore } from '@mui/icons-material'
import { debounce } from 'lodash'

interface FlexTextFieldProp {
    textLabel: string
    placeHolder: string
    fullWidth?: boolean
    isDropDown?: boolean
    secondaryDropDown?: boolean
    secondaryDropDownOptions?: string[]
    inputType?: string
    horizontal?: boolean
    fontSize?: number
    children?: React.ReactNode
    leadingIcon?: ReactNode
    value?: string
    width?: number
    height?: number
    secondaryValue?: string
    regex?: RegExp
    onChange: (value: string) => void
    onSecondaryChange?: (value: string) => void
    onKeyStroke?: (event: React.KeyboardEvent) => void
    inputRef?: Ref<HTMLInputElement>
    required?: boolean
}

export default function FlexTextField({
    textLabel,
    placeHolder,
    fullWidth,
    isDropDown,
    inputType = 'text',
    horizontal = false,
    children,
    width,
    fontSize = 18,
    leadingIcon,
    regex,
    onChange,
    value = '',
    onKeyStroke,
    required = false,
    inputRef,
}: FlexTextFieldProp) {
    const [error, setError] = useState(false)
    const handleChange = (value: string) => {
        if (regex) {
            debounce((value: string) => {
                setError(!regex.test(value))
            }, 1000)(value)
        }
        onChange(value)
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && onKeyStroke) {
            onKeyStroke(event)
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: horizontal ? 'row' : 'column',
                alignItems: horizontal ? 'center' : 'start',
                justifyContent: horizontal ? 'space-between' : 'start',
            }}
        >
            <Typography
                sx={{
                    fontSize: `${fontSize}px`,
                    fontWeight: '600',
                }}
            >
                {textLabel} {required && <span className="text-red-500 text-lg mt-2">*</span>}
            </Typography>
            <Spacer height={5} />

            <TextField
                id="filled-start-adornment"
                sx={{
                    width: width,
                    '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                            borderColor: '#E05C34', // Hover state border
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#E05C34', // Focus state border
                        },
                    },
                }}
                size="small"
                select={isDropDown}
                fullWidth={horizontal ? false : fullWidth}
                SelectProps={{
                    IconComponent: ExpandMore, // Custom dropdown icon
                }}
                type={inputType}
                placeholder={placeHolder}
                value={isDropDown ? placeHolder : value}
                onChange={(event) => handleChange(event.target.value)}
                error={error}
                helperText={error && 'Invalid format'}
                inputRef={inputRef}
                InputProps={{
                    startAdornment: leadingIcon && <InputAdornment position="start">{leadingIcon}</InputAdornment>,
                    sx: {
                        fontFamily: 'Inter',
                        fontSize: `${fontSize}px`,
                        padding: '0px', // Adjust the padding to reduce border padding
                        margin: '0px',
                    },
                }}
                InputLabelProps={{
                    sx: {
                        fontFamily: 'Inter',
                        fontSize: `${fontSize}px`,
                        padding: '0px', // Adjust the padding to reduce border padding
                        margin: '0px',
                    },
                }}
                onKeyDown={handleKeyDown}
            >
                {isDropDown && children}
            </TextField>
        </div>
    )
}
