import { Settings, Speed, Tune } from '@mui/icons-material'
import { Typography } from '@mui/material'
import Spacer from '../../components/common/util/spacer'

interface FeatureChipProp {
    featureType: string
}

const iconStyle = { color: 'gray', height: '25px', width: '25px' }

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export default function FeatureTypeChip({ featureType }: FeatureChipProp) {
    return (
        <div
            style={{
                border: '1px solid gray',
                borderRadius: '5px',
                display: 'flex',
                padding: '5px 10px 5px 10px',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
            }}
        >
            {featureType === 'meter' && <Speed sx={iconStyle} />}
            {featureType === 'boolean' && <Tune sx={iconStyle} />}
            {featureType === 'config' && <Settings sx={iconStyle} />}
            <Spacer width={5} />
            <Typography fontSize={16} fontWeight={600} color={'gray'}>
                {capitalizeFirstLetter(featureType)}
            </Typography>
        </div>
    )
}
