import { KeyboardArrowDown } from '@mui/icons-material'
import { Typography } from '@mui/material'
import React from 'react'
import Spacer from '../../components/common/util/spacer'

interface AddFeatureProps {
    leadingIcon: React.ReactNode
    textLabel: string
    onTap?: () => void
}
export default function AddFeatureButton({ leadingIcon, textLabel, onTap }: AddFeatureProps) {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '10px',
                flexGrow: '1',
                height: '40px',
                border: '1px solid #E05C34',
                backgroundColor: '#D1563133',
                borderRadius: '5px',
                cursor: 'pointer',
            }}
            onClick={onTap}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {leadingIcon}
                <Spacer width={10} />
                <Typography fontSize={16} fontWeight={'bold'}>
                    {textLabel}
                </Typography>
            </div>
            <KeyboardArrowDown />
        </div>
    )
}
