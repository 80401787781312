import { createContext, ReactNode, useState, useEffect } from 'react'

// Define the shape of the context value
interface EnvContextType {
    envId: string | null
    setEnvId: (envId: string | null) => void
}

const initialEnvId: string | null = localStorage.getItem('envId')
export const EnvContext = createContext<EnvContextType>({
    envId: initialEnvId,
    setEnvId: () => {},
})

interface EnvContextProviderProps {
    children: ReactNode
}

export const EnvContextProvider = ({ children }: EnvContextProviderProps) => {
    const [envId, setEnvId] = useState<string | null>(initialEnvId)

    useEffect(() => {
        if (envId !== null) {
            localStorage.setItem('envId', envId)
        } else {
            localStorage.removeItem('envId')
        }
    }, [envId])

    return <EnvContext.Provider value={{ envId, setEnvId }}>{children}</EnvContext.Provider>
}
