import { FC, useState } from 'react'
import { ModelGuardRail } from '@/api/models/model_guardrail.ts'
import { validateEffectValue } from '@/utils/utility_functions.ts'
import FlexButton from '@/components/common/button/button.tsx'
import { InputAdornment, TextField } from '@mui/material'

interface DiscountProps {
    onDiscountApplied: (fixed: number, recurring: number) => void
    label: string
    modelGuardRail?: ModelGuardRail[]
}

const DiscountSection: FC<DiscountProps> = ({ onDiscountApplied, modelGuardRail }) => {
    const [discountFixed, setDiscountFixed] = useState<number>(0)
    const [discountRecurring, setDiscountRecurring] = useState<number>(0)
    const [errorFixed, setErrorFixed] = useState<boolean>(false)
    const [errorRecurring, setErrorRecurring] = useState<boolean>(false)

    const handleChange = (
        value: string,
        setValue: (val: number) => void,
        effectOn: string,
        setError: (err: boolean) => void
    ) => {
        const numberValue = value === '' ? 0 : parseInt(value)
        setValue(numberValue)

        const guardRail = modelGuardRail?.find((item) => item.effect_on === effectOn)
        if (guardRail && !validateEffectValue(numberValue, guardRail.operator, guardRail.effect_value)) {
            setError(true)
        } else {
            setError(false)
        }
    }

    return (
        <div className="p-4 bg-white rounded-md shadow-md">
            <h1 className={'mb-1 text-base font-semibold text-primary'}>Discount</h1>

            <div className="space-y-6">
                {/* Fixed Charges Input */}
                <div>
                    <label className="block text-sm font-medium text-gray-600 mb-1">Fixed charges</label>
                    <TextField
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                color: '#000',
                                fontFamily: 'Arial',
                                fontWeight: 'bold',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'gray',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#f97316',
                                    borderWidth: '2px',
                                },
                            },
                            '& .MuiInputLabel-outlined': {
                                color: '#2e2e2e',
                                fontWeight: 'bold',
                            },
                        }}
                        variant="outlined"
                        size="small"
                        value={discountFixed}
                        error={errorFixed}
                        helperText={errorFixed && 'Discount exceeds the maximum value'}
                        onChange={(e) =>
                            handleChange(e.target.value, setDiscountFixed, 'cadence_fixed_price', setErrorFixed)
                        }
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                    />
                </div>

                {/* Recurring Charges Input */}
                <div>
                    <label className="block text-sm font-medium text-gray-600 mb-1">Recurring charges</label>
                    <TextField
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                color: '#000',
                                fontFamily: 'Arial',
                                fontWeight: 'bold',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'gray',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#f97316',
                                    borderWidth: '2px',
                                },
                            },
                            '& .MuiInputLabel-outlined': {
                                color: '#2e2e2e',
                                fontWeight: 'bold',
                            },
                        }}
                        variant="outlined"
                        size="small"
                        value={discountRecurring}
                        error={errorRecurring}
                        helperText={errorRecurring && 'Discount exceeds the maximum value'}
                        onChange={(e) =>
                            handleChange(
                                e.target.value,
                                setDiscountRecurring,
                                'cadence_recurring_price',
                                setErrorRecurring
                            )
                        }
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                    />
                </div>

                {/* Apply Button */}
                <FlexButton
                    textSize={14}
                    width={100}
                    height={36}
                    radius={8}
                    text="Apply"
                    onClick={() => {
                        if (!errorFixed && !errorRecurring) {
                            onDiscountApplied(discountFixed, discountRecurring)
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default DiscountSection
