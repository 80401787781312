import { useState } from 'react'
import { Plan } from '@/api/models/model_product_detail'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { PlanConfig } from '../CustomerSubscription'

interface SearchableSelectProps {
    plans: Plan[]
    selectedPlans: PlanConfig[]
    onSelectPlan: (plan: Plan | null) => void
}

export default function SearchableSelect({ plans, selectedPlans, onSelectPlan }: SearchableSelectProps) {
    const [_selectedOption, setSelectedOption] = useState<string | null>(null)

    return (
        <Select
            onValueChange={(selectedOption) => {
                setSelectedOption(selectedOption)
                const selectedPlan = plans?.find((plan) => plan.name === selectedOption)
                onSelectPlan(selectedPlan || null)
            }}
        >
            <SelectTrigger className="w-full">
                {/* <SelectValue>{selectedOption || <span className="text-gray-500">Select Add-on</span>}</SelectValue> */}
                <SelectValue placeholder={'Select Add-on'}>
                    {<span className="text-gray-500">Select Add-on</span>}
                </SelectValue>
            </SelectTrigger>
            <SelectContent>
                {plans
                    ?.filter((value) => !selectedPlans.some((selectedPlan) => selectedPlan.name === value.name))
                    .map((value, index) => (
                        <SelectItem key={index} value={value.name}>
                            {value.name}
                        </SelectItem>
                    ))}
            </SelectContent>
        </Select>
    )
}
