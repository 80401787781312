import Spacer from '../util/spacer'

interface SwitchSelectorProps {
    options: string[]
    width?: number | undefined
    activeOption: string
    handleSwitchClick: (option: string) => void
}
export default function SwitchSelector({ options, handleSwitchClick, width, activeOption }: SwitchSelectorProps) {
    return (
        <div
            style={{
                width: width ?? '280px',
                backgroundColor: 'white',
                borderRadius: '5px',
                borderColor: '#0000001A',
                borderWidth: '1px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingLeft: '5px',
                paddingRight: '5px',
                userSelect: 'none',
            }}
        >
            <div
                style={{
                    fontFamily: 'Inter',
                    padding: '5px',
                    borderRadius: '5px',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    color: activeOption === options[0] ? '#E85410' : 'gray',
                    borderColor: activeOption === options[0] ? '#E05D344D' : 'transparent',
                    backgroundColor: activeOption === options[0] ? '#FAE5DE' : 'transparent',
                }}
                onClick={() => handleSwitchClick(options[0])}
            >
                {options[0]}
            </div>
            <Spacer width={5} />
            <div
                className="ToggleItem"
                style={{
                    padding: '5px',
                    fontFamily: 'Inter',
                    borderRadius: '5px',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    color: activeOption === options[1] ? '#E85410' : 'gray',
                    borderColor: activeOption === options[1] ? '#E05D344D' : 'transparent',
                    backgroundColor: activeOption === options[1] ? '#FAE5DE' : 'transparent',
                }}
                onClick={() => handleSwitchClick(options[1])}
            >
                {options[1]}
            </div>
        </div>
    )
}
