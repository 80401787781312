import { ModelCustomer } from '@/api/models/model_customer'
import CustomerDetail from '../widgets/CustomerDetail'
import Spacer from '@/components/common/util/spacer'
import { Button } from '@/@ui-lib/atoms'
import { Plus } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'

export default function Overview({ customer }: { customer: ModelCustomer | undefined }) {
    const navigate = useNavigate()
    const onAddSubscription = () => navigate(`/customers/subscription?id=${customer!.id}`)
    return (
        <div className="w-full ">
            {customer && <CustomerDetail customer={customer!} />}
            <Spacer height={20} />
            <div className="px-5 py-3 border rounded-lg bg-white border-orange-500 shadow-md shadow-gray-300">
                <span className="text-xl font-inter font-bold text-orange-600">Subscriptions</span>
                <Spacer height={5} />
                <div className="flex justify-between items-center">
                    <span>Manage subscription for this customer</span>
                    <Button
                        label="Add"
                        prefixIcon={<Plus weight="bold" height={17} width={17} />}
                        type="outlined"
                        onClick={onAddSubscription}
                    />
                </div>
                <Spacer height={10} />
                <div className="px-4 py-2 text-sm rounded-lg bg-gray-200">
                    No active subscriptions. Add a new subscription
                </div>
            </div>
        </div>
    )
}
