import { Alert, Box, CircularProgress, Container, Typography } from '@mui/material'
import Spacer from '../../components/common/util/spacer'
import PriceFormPopup from '../../components/price_form/price_form_popup'
import { useEffect, useState } from 'react'
import { ProductDetailAPI } from '@/api/req/product_detail_api.ts'
import FLexTable from '../../components/common/table/table'
import SwitchSelector from '../../components/common/switch_selector/switch_selector'
import FlexDropdown from '../../components/common/dropdown/dropdown'
import { ModelProduct } from '@/api/models/model_product.ts'
import { ProductAPI } from '@/api/req/product_api.ts'
import { Plan } from '@/api/models/model_product_detail.ts'
import FlexColors from '@/utils/colors.ts'
import PlanCard from '@/components/plan_card/plan_card'

export default function PricePage() {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [billing, setBilling] = useState('Monthly')
    const [plans, setPlans] = useState<Plan[]>([])
    const [addOns, setAddOns] = useState<Plan[]>([])
    const [products, setProducts] = useState<ModelProduct[]>()

    useEffect(() => {
        ProductAPI.getAll().then((result: ModelProduct[]) => {
            setProducts(result)
            setLoading(true)
            ProductDetailAPI.getAll(result[0].id)
                .then((product) => {
                    setPlans(product.plans)
                    setAddOns(product.add_ons)
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }, [])

    const onHandleChange = (id: string) => {
        setLoading(true)
        ProductDetailAPI.getAll(id)
            .then((product) => {
                setPlans(product.plans)
                setAddOns(product.add_ons)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Container>
            <Spacer height={10} />
            <div
                className={'shadow-lg bg-white'}
                style={{
                    alignContent: 'start',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Typography fontWeight={700} fontSize={32}>
                    Pricing Page
                </Typography>
                <Spacer height={20} />

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {products && (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography fontWeight={600} fontSize={18}>
                                Select product
                            </Typography>
                            <Spacer width={10} />

                            <FlexDropdown
                                options={products.map((product) => product.name)}
                                onSelect={(selectedOption) => {
                                    const selectedProduct = products.find((product) => product.name === selectedOption)
                                    onHandleChange(selectedProduct!.id)
                                }}
                            />
                        </div>
                    )}
                    {!products && <div></div>}
                    <SwitchSelector
                        options={['Billed Monthly', 'Billed Annually']}
                        width={280}
                        handleSwitchClick={(value) => {
                            if (value === 'Billed Monthly') {
                                setBilling('Monthly')
                            } else {
                                setBilling('Annually')
                            }
                        }}
                        activeOption={billing === 'Monthly' ? 'Billed Monthly' : 'Billed Annually'}
                    />
                </div>
            </div>

            <Spacer height={20} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {loading && (
                    <div
                        className={
                            'min-h-[200px] w-full flex justify-center items-center bg-white rounded-md shadow-md'
                        }
                    >
                        <CircularProgress size={50} style={{ color: FlexColors.primary }} />
                    </div>
                )}
            </div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                {!loading && (!plans || plans.length === 0) && (
                    <Box sx={{ width: '100%' }}>
                        <Alert severity="warning">No plans available</Alert>
                    </Box>
                )}
                {!loading &&
                    plans &&
                    plans.length > 0 &&
                    plans.map((plan, index) => (
                        <Box
                            key={index}
                            sx={{
                                flex: '1 1 90%',
                                ml: index === 0 ? 0 : 1,
                                mr: index === plans.length - 1 ? 0 : 1,
                                justifyContent: 'space-between',
                            }}
                        >
                            <PlanCard key={index} plan={plan} billing={billing} />
                        </Box>
                    ))}
            </Box>
            <PriceFormPopup open={open} onClose={handleClose} />
            <Spacer height={10} />
            {!loading && (
                <div>
                    <Typography fontWeight={700} fontSize={24}>
                        Add-ons
                    </Typography>
                    <Spacer height={10} />

                    <FLexTable columnlabel={['Name', 'Slug', 'Status', 'Last Updated']} data={addOns} />
                </div>
            )}
        </Container>
    )
}
