import React from 'react'
import { FeatureMapping, Price } from '../api/models/model_product_detail'
import { Card, Typography } from '@mui/material'
import Spacer from '../components/common/util/spacer'
import { formatNumberUS } from './utility_functions'

export function getPlanPriceDetails(prices: Price[], showMonthly: boolean): React.ReactNode {
    return (
        <div>
            {prices &&
                prices
                    .filter((prices) => prices.billing_period === (showMonthly ? 'MONTHLY' : 'ANNUALLY'))
                    .map((price, index) => (
                        <Card key={index} elevation={0}>
                            {price.billing_cadence === 'ONETIME' ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography fontWeight={800} fontSize={16} color={'#E85426'}>
                                        {'₹ ' + price.monthly_price}
                                    </Typography>
                                    <Spacer width={5} />
                                    <Typography fontWeight={500} fontSize={14}>
                                        One Time
                                    </Typography>
                                </div>
                            ) : (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography fontWeight={800} fontSize={16} color={'#E85426'}>
                                        {'₹ ' + (showMonthly ? price.monthly_price : price.annual_price)}
                                    </Typography>
                                    <Spacer width={5} />
                                    <Typography fontWeight={500} fontSize={14}>
                                        Billed {showMonthly ? 'Monthly' : 'Annually'}
                                    </Typography>
                                </div>
                            )}
                        </Card>
                    ))}
        </div>
    )
}
export function showFeatureDetail(feature: FeatureMapping): React.ReactNode {
    let value = ''

    if (feature.feature.type === 'meter') {
        const usageLimit = feature.configuration.usage_limit
        const hasUnlimitedUsage = feature.configuration.has_unlimited_usage
        const unit = usageLimit > 0 ? feature.feature.unit_plural : feature.feature.unit

        if (usageLimit === 0) {
            value = ''
        } else {
            value = formatNumberUS(usageLimit)
        }

        if (hasUnlimitedUsage) {
            value += ' Unlimited'
        } else {
            value += ` ${unit}`
        }

        value += ` ${feature.feature.name} ${feature.configuration.reset_period.toLowerCase()}`
    } else if (feature.feature.type === 'config') {
        value = feature.configuration.config_value + ' ' + feature.feature.name
    } else {
        value = feature.feature.name
    }

    return (
        <div>
            <Typography fontWeight={600} fontSize={14}>
                {value}
            </Typography>
        </div>
    )
}
